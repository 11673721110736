<template>
  <div>
    <div class="head-for-bg mobile-for-head" :style="{ backgroundImage: `url(${$store.getters.backgroundImage})` }">
      <img :src="$store.getters.backgroundImage" class="hide-mobile" alt="" />
      <div class="for-title pl-res-10 pr-res-10 top-30">
        <span>Pejabat</span>
        <p class="text-center">
          Badan Penyelenggara Jaminan Produk Halal (BPJPH) adalah sebuah badan
          yang terbentuk dibawah naungan Kementerian Agama
        </p>
      </div>
    </div>
    <div class="container">
      <div class="_sub-container pad-20">
        <div class="breadcump">
          <ul>
            <li class="">
              <a class="hide-mobile" href="/profile">Profile</a>
              <a class="hide-desktop" href="#">Profile <i class="fa fa-angle-right"></i> </a>
              <h4 class=" hide-desktop">Pejabat</h4>
            </li>
            <li class=" hide-mobile nomargin">Pejabat</li>
          </ul>
        </div>
        <div class="content-detail-pejabat">
          <div class="flex d-res-block _head" v-if="head">
            <div class="_img ">
              <template v-if="head.photo">
                 <img
                  class="img"
                  :src="head.photo"
                  alt=""
                />
              </template>
              <template v-else>
                <img
                  class="img"
                  src="@/assets/images/compro/misc/default-profile.jpg"
                  alt=""
                />
              </template>
            </div>
            <div class="content">
              <h2 class="font-weight-6 font-24 mb-9">
                {{ head.name }}
              </h2>
              <span class="d-block font-14 color-grey mb-9">
                {{ head.position }}
              </span>
              <p class="mb-10" v-html="head.description"></p>
              <div class="float-left w-50 w-res-100 pb-4">
                <span class="font-weight-6 font-14 mb-10 d-block">Karir</span>
                <template v-for="(data, i) in head.official_data">
                  <span class="d-block font-14 mb-4" v-if="data.type == 'career'" :key="i">
                    {{ data.description }}
                  </span>
                </template>
              </div>
              <div class="float-left w-50 w-res-100 pb-4">
                <span class="font-weight-6 font-14 mb-10 d-block">Pendidikan</span>
                <template v-for="(data, i) in head.official_data">
                  <span class="d-block font-14 mb-4" v-if="data.type == 'education'" :key="i">
                    {{ data.description }}
                  </span>
                </template>
              </div>
            </div>
          </div>
          <div class="contain-grid mt-4">
            <!-- <div class="row" v-if="eselon1.length">
              <div class="col-md-12">
                <h2 class="font-weight-6 pb-4 d-block text-res-center">Pejabat Eselon I</h2>
              </div>
              <template v-for="(pejabat, i) in eselon1">
                <div class="col-md-3 for-grid-ph" :key="i" @click="popup = pejabat" v-b-modal.modal-profile>
                  <template v-if="pejabat.photo">
                    <img
                      class="margin-auto-res"
                      :src="pejabat.photo"
                      alt=""
                    />
                  </template>
                  <template v-else>
                    <img
                      class="margin-auto-res"
                      src="@/assets/images/compro/misc/default-profile.jpg"
                      alt=""
                    />
                  </template>
                  <span class="d-block font-14 text-res-center font-weight-6 mb-9 mt-11">
                    {{ pejabat.name }}
                  </span>
                  <span class="d-block font-12 color-grey ln-1-5 mb-9 text-res-center">
                    {{ pejabat.position }}
                  </span>
                </div>
              </template>
            </div> -->

            <div class="row" v-if="eselon1.length">
              <div class="col-md-12">
                <h2 class="font-weight-6 pb-4 d-block ">Pejabat Eselon I</h2>
              </div>
            </div>
            <slick ref="slick" class="slickHome-sec-3 slick-for-pejabat" :options="slickSec3" v-if="eselon1.length">
                
                <div class=" for-grid-ph"  v-for="(pejabat, i) in eselon1" :key="i"  @click="popup = pejabat" v-b-modal.modal-profile>
                  <template v-if="pejabat.photo">
                    <img
                      class="margin-auto-res"
                      :src="pejabat.photo"
                      alt=""
                    />
                  </template>
                  <template v-else>
                    <img
                      class="margin-auto-res"
                      src="@/assets/images/compro/misc/default-profile.jpg"
                      alt=""
                    />
                  </template>
                  <span class="d-block font-14 w-res-70 margin-auto text-res-center font-weight-6 mb-9 mt-11">
                    {{ pejabat.name }}
                  </span>
                  <span class="d-block font-12 w-res-70 margin-auto color-grey ln-1-5 mb-9 text-res-center">
                    {{ pejabat.position }}
                  </span>
                </div> 
            </slick>
            
            <div class="row mt-res-20" v-if="eselon2.length">
              <div class="col-md-12">
                <h2 class="font-weight-6 pb-4 d-block  ">Pejabat Eselon II</h2>
              </div>
            </div>
             <slick ref="slick" class="slickHome-sec-3 slick-for-pejabat" :options="slickSec3" v-if="eselon2.length">
                
                <div class=" for-grid-ph"  v-for="(pejabat, i) in eselon2" :key="i"  @click="popup = pejabat" v-b-modal.modal-profile>
                  <template v-if="pejabat.photo">
                    <img
                      class="margin-auto-res"
                      :src="pejabat.photo"
                      alt=""
                    />
                  </template>
                  <template v-else>
                    <img
                      class="margin-auto-res"
                      src="@/assets/images/compro/misc/default-profile.jpg"
                      alt=""
                    />
                  </template>
                  <span class="d-block font-14  w-res-70 margin-auto text-res-center font-weight-6 mb-9 mt-11">
                    {{ pejabat.name }}
                  </span>
                  <span class="d-block font-12 w-res-70 margin-auto color-grey ln-1-5 mb-9 text-res-center">
                    {{ pejabat.position }}
                  </span>
                </div> 
            </slick>
            
            <div class="row mt-res-20" v-if="eselon3.length">
              <div class="col-md-12">
                <h2 class="font-weight-6 pb-4 d-block ">Pejabat Eselon III</h2>
              </div>
            </div>
             <slick ref="slick" class="slickHome-sec-3 slick-for-pejabat" :options="slickSec3" v-if="eselon3.length">
                
                <div class=" for-grid-ph"  v-for="(pejabat, i) in eselon3" :key="i"  @click="popup = pejabat" v-b-modal.modal-profile>
                  <template v-if="pejabat.photo">
                    <img
                      class="margin-auto-res"
                      :src="pejabat.photo"
                      alt=""
                    />
                  </template>
                  <template v-else>
                    <img
                      class="margin-auto-res"
                      src="@/assets/images/compro/misc/default-profile.jpg"
                      alt=""
                    />
                  </template>
                  <span class="d-block font-14 w-res-70 margin-auto text-res-center font-weight-6 mb-9 mt-11">
                    {{ pejabat.name }}
                  </span>
                  <span class="d-block font-12 w-res-70 margin-auto  color-grey ln-1-5 mb-9 text-res-center">
                    {{ pejabat.position }}
                  </span>
                </div> 
            </slick>
            <div class="row mt-res-20" v-if="eselon4.length">
              <div class="col-md-12">
                <h2 class="font-weight-6 pb-4 d-block  ">Pejabat Eselon IV</h2>
              </div>
            </div>
             <slick ref="slick" class="slickHome-sec-3 slick-for-pejabat" :options="slickSec3" v-if="eselon4.length">
                
                <div class=" for-grid-ph"  v-for="(pejabat, i) in eselon4" :key="i"  @click="popup = pejabat" v-b-modal.modal-profile>
                  <template v-if="pejabat.photo">
                    <img
                      class="margin-auto-res"
                      :src="pejabat.photo"
                      alt=""
                    />
                  </template>
                  <template v-else>
                    <img
                      class="margin-auto-res"
                      src="@/assets/images/compro/misc/default-profile.jpg"
                      alt=""
                    />
                  </template>
                  <span class="d-block font-14 w-res-70 margin-auto text-res-center font-weight-6 mb-9 mt-11">
                    {{ pejabat.name }}
                  </span>
                  <span class="d-block w-res-70 margin-auto font-12 color-grey ln-1-5 mb-9 text-res-center">
                    {{ pejabat.position }}
                  </span>
                </div> 
            </slick>
            

          </div>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-profile"
      size="lg"
      centered
      class="modal-halal"
      hide-footer
      hide-header
    >
      <a
        class="for-close"
        href="#"
        @click.prevent="$bvModal.hide('modal-profile')"
      >
        <i class="fa fa-close"></i>
      </a>
      <div class="row">
        <div class="col-md-4 modal-for-photo">
          <template v-if="popup.photo">
            <img
              :src="popup.photo"
              alt=""
            />
          </template>
          <template v-else>
            <img
              src="@/assets/images/compro/misc/default-profile.jpg"
              alt=""
            />
          </template>
          <div class="flex justify-center pad-10">
            <a :href="popup.youtube" v-if="popup.youtube !== ''">
              <img src="@/assets/images/compro/icon/ytobe.svg" alt="" />
            </a>
            <a :href="popup.twitter" v-if="popup.twitter !== ''">
              <img
                class="ml-1 mr-1"
                src="@/assets/images/compro/icon/tw.svg"
                alt=""
              />
            </a>
            <a :href="popup.instagram" v-if="popup.instagram !== ''">
              <img
                class="ml-1 mr-1"
                src="@/assets/images/compro/icon/insta.svg"
                alt=""
              />
            </a>
            <a :href="popup.facebook" v-if="popup.facebook !== ''">
              <img src="@/assets/images/compro/icon/fb.svg" alt="" />
            </a>
          </div>
        </div>
        <div class="col-md-8 pt-4 pb-10">
          <h4 class="font-weight-6 font-14 mb-10">{{ popup.name }}</h4>
          <span class="d-block font-14 color-grey mb-20">{{ popup.position }}</span>
          <p class="color-grey font-14" v-html="popup.description"></p>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<style scoped>
  .mt-11 {
    margin-top: 11px !important;
  }
</style>

<script>
import Slick from "vue-slick";

export default {
  name: 'pejabat',
   components: {
    Slick,
  },
  data() {
    return {
      head: {},
      eselon1: [],
      eselon2: [],
      eselon3: [],
      eselon4: [],
      popup: {},
      slickSec3: {
        autoplay: false,
        centerMode: false,
        slidesToShow: 4,
        autoplaySpeed: 5000,
        dots: false,
        arrows: true,
        prevArrow: '<button class="slide-arrow prev-arrow"></button>',
        nextArrow: '<button class="slide-arrow next-arrow"></button>',
                customPaging: function(slick,index) {
        return '<a data-at="'+index + 1 +'"></a>';
      },
      responsive: [ 
        {
          breakpoint: 780,
          settings: {
            dots: true,
                  arrows: false,
            slidesToShow: 2,
                centerMode: true,
                variableWidth: true
          }
        },
        {
          breakpoint: 480,
          settings: {
              dots: true,
                    arrows: false,
                slidesToShow: 2,
                  centerMode: true,
                  variableWidth: true
          }
        }
      ]
    },
    }
  },
  mounted() {
    this.getHeadOfficial()
    this.getOfficial('Eselon I')
    this.getOfficial('Eselon II')
    this.getOfficial('Eselon III')
    this.getOfficial('Eselon IV')
  },
  methods: {
    getHeadOfficial() {
      this.axios.get('/official/filter/featured')
        .then( response => {
          this.head = response.data.result.official
        })
    },
    getOfficial(category) {
      this.axios.get('/official/', {
        params: {
          category: category,
          page: 1,
          limit: 10
        }
      })
        .then( response => {
          let official = response.data.result.officials

          if (category == 'Eselon I') {
            this.eselon1 = official.filter( pejabat => !pejabat.featured)
          } else if (category == 'Eselon II') {
            this.eselon2 = official.filter( pejabat => !pejabat.featured)
          } else if (category == 'Eselon III') {
            this.eselon3 = official.filter( pejabat => !pejabat.featured)
          } else if (category == 'Eselon IV') {
            this.eselon4 = official.filter( pejabat => !pejabat.featured)
          }
        })
    }
  }
}
</script>